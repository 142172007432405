import eduImg from "../../../images/basl/edu.png";
import profImg from "../../../images/basl/prof.png";
import intentImg from "../../../images/basl/intent.png";

export const eligibilty = [{
  head: 'Education',
  desc: 'We encourage students from all educational fields to apply',
  img: eduImg
},{
  head: 'Professional Experience',
  desc: '0-4 years of experience is ideal but not a necessity',
  img: profImg
},{
  head: 'Intent',
  desc: 'Deep interest & intent in building a business & sales career',
  img: intentImg
}];