import React from "react";
import "./styles.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Bar1 from "../../../images/basl/01.png";
import Bar2 from "../../../images/basl/02.png";
import Bar3 from "../../../images/basl/03.png";
import Bar4 from "../../../images/basl/04.png";

const GraphsMob = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="graphs pc-flex-none" ref={ref}>
      {inView && <>
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 70 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.55, delay: 0.55 }}
          src={Bar1}
          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: -30 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.55, delay: 0.25 }}
          src={Bar2}
          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 70 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.65 }}
          src={Bar3}
          className="graph-img"
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 30 },
            visible: { opacity: 1, y: -45 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.3 }}
          src={Bar4}
          className="graph-img"
        />
      </> }
    </div>
  );
};

export default GraphsMob;
