import React from "react";
import { data } from "./data";
import greyTick from "../../../images/basl/projects/greyTick.png";

const cardsMob = () => {
  return (
    <div className="project-card-container">
      {data.map((project, index) => (
        <div
          id={`sticky-card-${index + 1}`}
          key={index}
          className="sticky-card"
        >
          <div className="project-card">
            <div className="p-card">
              <div className="p-info">
                <div
                  className="project-name"
                  style={{ color: project.color }}
                >
                  {" "}
                  {project.project}
                </div>
                <div className="project-title"> {project.title}</div>
                <div className="duration">
                Duration:{" "}
                  <span className="duration-val">{project.duration}</span>
                </div>

                <div
                  className="p-desc"
                  dangerouslySetInnerHTML={{ __html: project.desc }}
                />
                <div className="p-disclaimer">{project.disclaimer}</div>
              </div>
              <div
                className="p-img mobile-hide"
                style={{ backgroundImage: `url(${project.bg})` }}
              >
                <div className="p-img-body">
                  {project.points.map((point) => {
                    return (
                      <div className="bullets">
                        <img src={greyTick} className="bulletImg" alt="->" />{" "}
                        {point}
                      </div>
                    );
                  })}
                  <div
                    className="p-highlight"
                    style={{ color: project.hColor }}
                  >
                    <img className="highlightImg" src={project.icon} />{" "}
                    {project.highlight}
                  </div>
                </div>
              </div>
              <div
                className="p-img pc-none" 
                style={{ backgroundImage: `url(${project.mobImg})` }}
              >
                <div className="p-img-body">
                  {project.points.map((point) => {
                    return (
                      <div className="bullets">
                        <img src={greyTick} className="bulletImg" alt="->" />{" "}
                        {point}
                      </div>
                    );
                  })}
                  <div
                    className="p-highlight"
                    style={{ color: project.hColor }}
                  >
                    <img className="highlightImg" src={project.icon} />{" "}
                    {project.highlight}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default cardsMob;
