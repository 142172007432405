import React from "react";
import "./styles.scss";
import img1 from "../../../images/basl/scholar1.png";
import img2 from "../../../images/basl/scholar2.png";
import img3 from "../../../images/basl/scholar3.png";
import img4 from "../../../images/basl/scholar4.png";
import img5 from "../../../images/basl/scholar5.png";
import img6 from "../../../images/basl/scholar6.png";

import { scholarships } from "./data";
import ScolarshipSub from "./ScolarshipSub";

const index = () => {
  const groupedScholarships = [scholarships.slice(0, 2), scholarships.slice(2, 4), scholarships.slice(4)];

  return (
    <div className="basl-scolarship">
      <div className="basl-scolarship-header">
        Scholarships of upto ₹40,000 are available under 6 categories.
      </div>
      <div className="basl-scolarship-sub">
        If you’ve got the skill, we will not let financing get in the way of
        your aspirations.
        <br />
        Check eligibility with your counselor.
      </div>
      <div className="scolar-flex">
        <div id="scholar-1" className="scolarshipCard">
          <img src={img1} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Women in Sales
              <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For women who want to join the sales trenches and demonstrate high
              capability for it
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img src={img2} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Entrepreneurial
              <br />
              Ambition Scholarship
            </div>
            <div className="scholar-det">
              For folks who want to start-up with a strong sales muscle
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img src={img3} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Sales Excellence <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks who have prior<br/> sales experience and <br/>have excelled at it
            </div>
          </div>
        </div>
      </div>
      <div className="scolar-flex">
        <div id="scholar-1" className="scolarshipCard">
          <img src={img4} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Young India <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For freshers who have
              <br /> delivered exceptional results in their extra/co-curriculars
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img src={img5} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Need Based <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks from families who dont have enough means to finance
              their education
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img src={img6} className="scholorimg" />
          <div className="scholar-cont">
            <div className="schol-head">
              Standout Performance
              <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks who ace it in <br/>Admission Process
            </div>
          </div>
        </div>
      </div>
      <div className="scolar-flex-mob">
        <div className="scholar-cards-row">
          {groupedScholarships.map((group, colIndex) => (
            <div key={colIndex} className="scholar-cards-col">
              {group.map((scholarship) => (
                <div key={scholarship.id} className="scolarshipCard">
                  <img src={scholarship.img} className="scholorimg" />
                  <div className="scholar-cont">
                    <div className="schol-head">{scholarship.head}</div>
                    <div className="scholar-det">{scholarship.detail}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <ScolarshipSub />
    </div>
  );
};

export default index;
