import React from "react";
import LazyLoad from "react-lazyload";
import youtubeIcon from "../../../images/mlp/youtube-icon.png";
import linkedinIcon from "../../../images/mlp/linkedin-icon.png";
import "./styles.scss";
import { useState } from "react";

function Card({
  video,
  image,
  alt,
  name,
  batch,
  company,
  description,
  profile,
  jobRole,
  specialisation,
  extraText,
  fn,
  uni
}) {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className="card-7"
      onClick={() =>
        fn([
          video,
          image,
          name,
          batch,
          company,
          description,
          jobRole,
          specialisation,
          extraText,
          uni
        ])
      }
    >
      <div className="type2-head space-between">
        <div className="type2-img-div">
          {image && <LazyLoad once>
            <img style={{borderRadius: "50%", border: "2px solid #f1ae13"}} src={image} alt={alt} title={name + " " + company} />
          </LazyLoad>}
          {!image && <div style={{aspectRatio: '1', background: '#f1ae13', borderRadius: '50%'}} />} 
        </div>
        <div className="social-apps">
          {video && (
            <a href={video} target="blank" >
              <img src={youtubeIcon} className="social-img" loading="lazy" />
            </a>
          )}

          {profile && <a href={profile} target="blank">
            <img src={linkedinIcon} className="social-img" loading="lazy" />
          </a>}
        </div>
      </div>
      <div className="type2-info">
        <div className="alumni-name">{name}</div>
        <div className="alumni-company">
          {company}
        </div>
        <div className="alumni-uni">
          {uni}
        </div>
        {/* <div className="specialisation">
          Specialisation: {specialisation} | {batch}
        </div> */}
      </div>
      <hr className="yellow-hr" />
      <div className="type2-description italic">
        {showMore ? description : description.substring(0, 230) + "... "}
        <span className="showMore" onClick={() => setShowMore(true)}>
          {!showMore && "show more"}
        </span>
      </div>
      <div className="type2-description_bold">{extraText}</div>
    </div>
  );
}

export default Card;
