import React from 'react';

const ScolarshipSub = () => {
  return (
    <div className='ScolarshipSub'>
      <div className="scholar-sub-card">
        <div className="sub-card-head">8 Terms</div>
        <div className="sub-card-detail">4 weeks each</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">500+</div>
        <div className="sub-card-detail">Hours</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">4</div>
        <div className="sub-card-detail">LIVE projects</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">Rs 11.2 L</div>
        <div className="sub-card-detail">Average CTC</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">2</div>
        <div className="sub-card-detail">Specializations</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">40+</div>
        <div className="sub-card-detail">CXOs and Leaders</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">80+</div>
        <div className="sub-card-detail">Hours of career prep</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">20+</div>
        <div className="sub-card-detail">Workshops</div>
      </div>
    </div>
  );
};

export default ScolarshipSub;
