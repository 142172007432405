import React from "react";
import "./styles.scss";
import undeline from "../../../../static/assets/new_underline.png";
import gaurav from "../../../images/basl/gaurav.png";
import amit from "../../../images/basl/amit.png";
import fahad from "../../../images/basl/fahad.png";
import varun from "../../../images/basl/varun.png";
import gauravAlt from "../../../images/basl/gauravAlt.png";
import amitAlt from "../../../images/basl/amitAlt.png";
import fahadAlt from "../../../images/basl/fahadAlt.png";
import varunAlt from "../../../images/basl/varunAlt.png";
import DotsBgRight from "../../../images/basl/salesDotsBgImg.png";
import DotsBgLeft from "../../../images/basl/salesDotsBgImgLeft.png";

const index = () => {
  return (
    <div className="basl-sales">
      <img src={DotsBgRight} loading='lazy' className='pc-sales-dots-right' />
      <img src={DotsBgLeft} loading='lazy' className='pc-sales-dots-left' />
      <div className="text-content">
        <div className="sales-sub-header">Why Sales?</div>
        <h2 className="sales-header">Sales - A career of <br className="pc-hide"/>champions</h2>
        <img src={undeline} loading="lazy" className="salesUndeline" />
      </div>
      <div className="sales-desc desk">
      Sales is arguably the most important and unchanging skill in the world. Hear it from the experts:
      </div>
      <div className="sales-card-container-2">
        <div className="sales-card-container">
          <div className="sales-card gaurav">
            <a href="https://www.linkedin.com/in/gauravrastogi/" target="_blank">
              <img src={gaurav} loading="lazy" className="salesImage" />
              <img src={gauravAlt} loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card fahad">
            <a href="https://www.linkedin.com/in/fahad-mistry/" target="_blank">
              <img src={fahad} loading="lazy" className="salesImage" />
              <img src={fahadAlt} loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card amit">
            <a href="https://www.linkedin.com/in/amit-agarwal-0103643/" target="_blank">
              <img src={amit} loading="lazy" className="salesImage" />
              <img src={amitAlt} loading="lazy" className="salesAlt" />
            </a>
          </div>
          <div className="sales-card varun">
            <a href="https://www.linkedin.com/in/varunsatia/" target="_blank">
              <img src={varun} loading="lazy" className="salesImage" />
              <img src={varunAlt} loading="lazy" className="salesAlt" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
