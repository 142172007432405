import React from 'react';
import PledgeImg from "../../../images/basl/pledgeHand.png";
import PledgeImgPc from "../../../images/basl/pledgeHandPc.png";

const Pledge = () => {
  return (
    <div className='pgp-pledge'>
      <div className="pgp-pledge-lt">
        <div className="pgp-course">
        PGP in Sales and Business Leadership has
        </div>
        <div className="pledge-title">
        Krafshala’s Placement Accountability Pledge
        </div>
        <div className="pledge-desc">
        If your job pays &lt; ₹7.5L, you get a 60% refund
        </div>
      </div>
      <div className="pgp-pledge-rt">
        <img src={PledgeImg} className='pledge-img pc-hide' loading='lazy' />
        <img src={PledgeImgPc} className='pledge-img mobile-hide' loading='lazy' />
      </div>
    </div>
  );
};

export default Pledge;
