import React from "react";
// import { Companies } from "./Companies";
import "./styles.scss";
import underline from "../../../images/csm/new-underline.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Bar1 from "../../../images/basl/01.png";
import Bar2 from "../../../images/basl/02.png";
import Bar3 from "../../../images/basl/03.png";
import Bar4 from "../../../images/basl/04.png";
import GraphMobile from "./GraphsMob";

export const PlacementsMain = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="bsl-main-pillars">
      <div className="bslp-student-slider">
        <div className="placement-sub-title">
          Kraftshala’s PGP in<br className="pc-hide"/> Sales and Business Leadership
        </div>
        <h2 className="pillars-head">Built on 4 core pillars</h2>
        
        <img src={underline} className="placement-underline" />

        <div className="graphs mobile-flex-none" ref={ref}>
          {inView && <><motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.45, delay: 0.25 }}
            src={Bar1}
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.55, delay: 0.45 }}
            src={Bar2}
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.70, delay: 0.65 }}
            src={Bar3}
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.80, delay: 0.75 }}
            src={Bar4}
            className="graph-img"
          />
          </>}
        </div>
      
        <GraphMobile />
      </div>
    </div>
  );
};

export default PlacementsMain;
