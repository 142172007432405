import React from "react";
import PlacementImg from "../../../images/basl/placementProcessImg.png";
import PlacementImgMob from "../../../images/basl/placementProcessImgMob.png";
import AdmissionImg from "../../../images/basl/admisionProcImg.png";
import AdmissionImgMob from "../../../images/basl/admisionProcImgMob.png";
import underline from "../../../images/csm/new-underline.png";
import { eligibilty } from "./eligibility";
import DotsBgRight from "../../../images/basl/pgpBgRight.png";
import DotsBgLeft from "../../../images/basl/pgpBgLeft.png";

const PlacementProcess = () => {
  return (
    <>
      <div className="career-title mtop-3">Placement process</div>
      <img src={underline} loading="lazy" className="placement-underline" />
      <img
        src={PlacementImg}
        loading="lazy"
        className="placement-prep-img mobile-hide"
      />
      <img
        src={PlacementImgMob}
        loading="lazy"
        className="placement-prep-img pc-hide"
      />

      <img
        src={DotsBgRight}
        loading="lazy"
        className="pc-placement-dots-right"
      />
      <img src={DotsBgLeft} loading="lazy" className="pc-placement-dots-left" />
      <div className="career-title mtop-3 section" id="admission">
        Admission Process
      </div>
      <img src={underline} loading="lazy" className="placement-underline" />
      <div className="Admission-prep-desc">
        3-Step Process which takes up almost 7-10 days from application to the
        final decision.{" "}
      </div>
      <img
        src={AdmissionImg}
        loading="lazy"
        className="AdmissionImg-prep-img mobile-hide"
      />
      <img
        src={AdmissionImgMob}
        loading="lazy"
        className="AdmissionImg-prep-img pc-hide"
      />

      <div className="career-title mtop-3">Eligibility criteria</div>
      <img src={underline} loading="lazy" className="placement-underline" />
      <div className="basl-eligibility-cards">
        {eligibilty &&
          eligibilty.map((eligibility,index) => {
            return (
              <div key={index} className="basl-eligibility-card">
                <img src={eligibility.img} className="basl-eligi-icon" loading="lazy" />
                <div>
                  <div className="basl-eligibility-head">{eligibility.head}</div>
                  <div className="basl-eligibility-desc">{eligibility.desc}</div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PlacementProcess;
