import React from "react";
import "./Floater.scss";
import sandClock from "../../../images/Bilingual/icons/sandclockIcon.png";
import CalenderIcon from "../../../images/Bilingual/icons/calenderIcon.png";
import JobIcon from "../../../images/Bilingual/icons/jobIcon.png";
import HigestCtc from "../../../images/Bilingual/icons/ectc.png";
import avgCtc from "../../../images/Bilingual/icons/actc.png";
import Mode from "../../../images/Bilingual/icons/mode.png";


const Floater = (props) => {
  return (
    <>
      <div className="page-floater-basl mobile-none">
        <div className="floater-content">
          <img className="floater-icon-basl" src={sandClock} loading="lazy" />
          <div className="floater-title">Program Duration </div>
          <div className="floater-sub">8 Months</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={JobIcon} loading="lazy" />
          <div className="floater-title">LIVE Projects</div>
          <div className="floater-sub">with Nestlé & Turbohire</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={HigestCtc} loading="lazy" />
          <div className="floater-title"> Highest CTC</div>
          <div className="floater-sub">
            of ₹17.2 LPA
          </div>
        </div>
        
        <div className="floater-content">
          <img className="floater-icon-basl" src={avgCtc} loading="lazy" />
          <div className="floater-title"> Average CTC</div>
          <div className="floater-sub">
            of ₹11.2 LPA
          </div>
        </div>

        <div className="floater-content">
          <img className="floater-icon-basl" src={Mode} loading="lazy" />
          <div className="floater-title">Mode</div>
          <div className="floater-sub">
            Online,<br className="pc-hide" /> Full-Time
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={CalenderIcon} loading="lazy" />
          <div className="floater-title"> Batch of 2025</div>
          <div className="floater-sub">
            Starts in Jan
          </div>
        </div>
       
      </div>

      <div className="page-floater-basl pc-none">
        <div className="floater-content">
          <img className="floater-icon-basl" src={sandClock} loading="lazy" />
          <div className="floater-title">Program Duration </div>
          <div className="floater-sub">8 Months</div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={JobIcon} loading="lazy" />
          <div className="floater-title">LIVE Projects</div>
          <div className="floater-sub">with Nestlé & Turbohire</div>
        </div>
        <hr class="yellow-line" />
        <div className="floater-content">
          <img className="floater-icon-basl" src={HigestCtc} loading="lazy" />
          <div className="floater-title"> Highest CTC</div>
          <div className="floater-sub">
            of ₹17.2 LPA
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={avgCtc} loading="lazy" />
          <div className="floater-title"> Average CTC</div>
          <div className="floater-sub">
            of ₹11.2 LPA
          </div>
        </div>
        <hr class="yellow-line" />
        <div className="floater-content">
          <img className="floater-icon-basl" src={Mode} loading="lazy" />
          <div className="floater-title">Mode</div>
          <div className="floater-sub">
            Online,<br className="pc-hide"/> Full-Time
          </div>
        </div>
        <div className="floater-content">
          <img className="floater-icon-basl" src={CalenderIcon} loading="lazy" />
          <div className="floater-title"> Batch of 2025</div>
          <div className="floater-sub">
            Starts in Jan
          </div>
        </div>
      </div>

      {/* <div className="page-floater-mob pc-hide">
        <StaticImage
          src="../../images/mlp/mlp-floater.webp"
          alt="batch starts 23 march"
        />
       
      </div> */}
    </>
  );
};

export default Floater;
