import React from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import { CommunityImages } from "./data";
import underline from "../../../images/csm/new-underline.png";

import "./styles.scss";

function Trainers() {
  const settings = {
    dots: false,
    infinite: true,
    type: Boolean,
    default: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
          // centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="basl-main-trainers section" id="experts">
      <div className="will-be-training will-be-training-bsl mlp-bg">
        <div class="index-bg">04</div>
        <div className="trainers-container-basl">
          <div className="basl-trainer-sub">Community</div>
          <img src={underline} className="underline-trainers-basl" />
          <div className="trainers-desc mobile-hide">Not just a network, get an inner circle of amazing leaders and  professionals in Sales and Marketing</div>
          <div className="basl-trainer-ylo mobile-hide">Leaders Unplugged - Weekly Series</div>
          <div className="basl-trainer-sub-head mobile-hide">Unmatched insights from these amazing folks</div>
          <div className="trainers-sliders">
            <Slider {...settings}>
              {CommunityImages?.map((info, key) => (
                <div className="trainer-card" key={key}>
                  <LazyLoad once>
                    <img
                      src={info.image}
                      alt={info.alt}
                      title={info.alt}
                      loading="lazy"
                      className="trainers-bslp-img"
                    />
                  </LazyLoad>
                </div>
              ))}
            </Slider>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainers;
