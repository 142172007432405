import React, { Fragment } from "react";
import "./styles.scss";
import newUnderline from "../../../images/csm/new-underline.png";
import placementTableMob from "../../../images/Bilingual/placementTableMob.png";
import placementTableMob2 from "../../../images/Bilingual/placementTableMob2.png";
import baslLogo from "../../../images/basl/pgplogo.png";
import yellowStrip from "../../../images/Bilingual/yellowDots.png";
import greyStrip from "../../../images/Bilingual/greylineDots.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import OverviewCard from "./overViewCard";
import OverViewMobile from "./OverViewMobile";

function Overview() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const scrollTo = (direction) => {
    let node = document.querySelector(".grid-container.pc-none");

    if (node) {
      let scrollWidth = direction === "left" ? node.scrollWidth : 0;

      node.scrollLeft = scrollWidth;
    }
  };

  return (
    <>
      <div className="overview-bsl">
        <div className="overview-container-main">
          <div className="overview-sub-title">GET Better Results, Faster!</div>
          <h2 className="overview-title">
            Higher ROI than 99.6% of all MBA programs
          </h2>
          <img
            src={newUnderline}
            className="overview-underline"
            loading="lazy"
          />
        </div>
        <div className="grid-container mobile-none" ref={ref}>
   
          <div className="index">
            <div className="head"></div>
            <div className="today index-today">
              <div className="day"> Today</div>
              <div className="day"> 2025</div>
              <div className="day"> 2026</div>
            </div>
            <div className="roi bordered">
              Roi
              <br />
              <div className="subRoi"> (Salary-Fee) / Fee*100</div>
            </div>
            <div className="roi">Time to deliver ROI</div>
          </div>
          <div className="bslp">
            <div className="head">
              <img src={baslLogo} loading="lazy" className="baslLogo" />
            </div>
            {inView && <>
              <div className="today">
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.25 }}
                >
                  <img src={yellowStrip} loading="lazy" className="yellowstrip" />
                </motion.Fragment>
              </div>
              <div className="roi bordered">
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.5 }}
                >
                  <div className="greenText">505%</div> (Fee 1.85L)
                </motion.Fragment>
              </div>
              <div className="roi">
                {" "}
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.8 }}
                >
                &lt;1 year{" "}
                </motion.Fragment>{" "}
              </div>
            </>}
          </div>
          <div className="mbas">
            <div className="head">MBAs (except top 20)</div>
            {inView && <>
              <div className="today">
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.25 }}
                >
                  <img src={greyStrip} loading="lazy" className="greystrip" />
                </motion.Fragment>
              </div>
              <div className="roi bordered">
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0  },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.5 }}
                >
                  <div className="redText"> -41%</div> (Fee 12L)
                </motion.Fragment>
              </div>

              <div className="roi">
                <motion.Fragment
                  variants={{
                    hidden: { opacity: 0,  y: 70 },
                    visible: { opacity: 1,  y: 0  },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.55, delay: 0.8 }}
                >
                  {" "}
                2 year{" "}
                </motion.Fragment>
              </div>
            </>}
          </div>
        </div>
          
        <div className="grid-container pc-none">
          <img
            src={placementTableMob}
            className="placementTableMob"
            onClick={() => scrollTo("left")}
          />
          <img
            src={placementTableMob2}
            className="placementTableMob-2"
            onClick={() => scrollTo("right")}
          />
        </div>
        <OverviewCard />
        <OverViewMobile />

      </div>
    </>
  );
}

export default Overview;
