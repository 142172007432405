import React, { useState } from "react";
import "./styles.scss";
import BenifitsTable from "./BenifitsTable";
import underline from "../../../images/csm/new-underline.png";

function Benefits() {
  const [table,setTable] = useState('roi');

  return (
    <div className="bsl-main-benefits">
      <div className="benefits-container">
        <div className="benefits-sub-title">KRAFTSHALA VS OTHERS</div>
        <h2 className="benefit-title-bsl">
          On Time, Money,<br className="pc-hide"/> Skills and Community
        </h2>
        <img src={underline} loading="lazy" className="benifits-underline" />
        <p className="bsl-desc desk">
        PGP in Sales & Business Leadership delivers better ROI than<br/>
        99.6% of all MBA programs (online or offline)
        </p>
        <p className="bsl-desc mob">
        PGP in Sales & Business Leadership<br/> delivers better ROI than
        99.6% of all<br/> MBA programs (online or offline)
        </p>
        <div className="cover">
          <div className="menu">
            <div
              className={`menu-item ${table === "roi" && "active"}`}
              onClick={() => setTable("roi")}
            >
                ROI
            </div>
            <div
              className={`menu-item ${table === "placements" && "active"}`}
              onClick={() => setTable("placements")}
            >
                Placements
            </div>
            <div
              className={`menu-item ${table === "community" && "active"}`}
              onClick={() => setTable("community")}
            >
                Community
            </div>
          
          </div>
        </div>
        <BenifitsTable tableName={table} setTable={setTable} />
      </div>
    </div>
  );
}

export default Benefits;
