
import Nandu from "../../../images/basl/community/nadkishore.png";
import Sambit from "../../../images/basl/community/sambit.png";
import Prian from "../../../images/basl/community/prian.png";
import Gaurav from "../../../images/basl/community/gaura.png";
import Sushrut from "../../../images/basl/community/sushrut.png";
import Fahad from "../../../images/basl/community/fahadcomunity.png";
import Ninad from "../../../images/basl/community/ninad.png";
import Himanchu from "../../../images/basl/community/himanshu.png";
import Vishal from "../../../images/basl/community/vishal.png";
import Ravi from "../../../images/basl/community/ravi.png";
import Amit from "../../../images/basl/community/agarwal.png";


export const CommunityImages = [
  {
    image: Nandu,
    alt: "KS Community Professional_nandu",
  },
  {
    image: Sambit,
    alt: "KS Community Professional_Sambit",
  },
  {
    image: Prian,
    alt: "KS Community Professional_Priyan",
  },
  {
    image: Gaurav,
    alt: "KS Community Professional_Gaurav",
  },
  {
    image: Sushrut,
    alt: "KS Community Professional_Sushrut",
  },
  {
    image: Fahad,
    alt: "KS Community Professional_Fahad",
  },
  {
    image: Ninad,
    alt: "KS Community Professional_Ninad",
  },
  {
    image: Himanchu,
    alt: "KS Community Professional_Himanchu",
  },
  {
    image: Vishal,
    alt: "KS Community Professional_Vishal",
  },
  {
    image: Ravi,
    alt: "KS Community Professional_Ravi",
  },
  {
    image: Amit,
    alt: "KS Community Professional_Amit",
  },
 
];
