import React from "react";
import "./styles.scss";
import underline from "../../../images/csm/new-underline.png";
import prepImg from "../../../images/basl/careerPrep.png";
import prepMobImg from "../../../images/basl/careerPrepMob.png";
import PlacementProcess from "./PlacementProcess";

const Certification = () => {
  return (
    <div className="career-prep " >
      <div className="career-title">80+ hours of career prep</div>
      <img src={underline} loading="lazy" className="c-p-underline" />

      <img src={prepImg} loading="lazy" className="careep-prep-img mobile-none" />
      <img src={prepMobImg} loading="lazy" className="careep-prep-img pc-none" />
      <PlacementProcess />
     
    </div>
  );
};

export default Certification;
