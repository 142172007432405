import React from 'react';
import "./styles.scss";
import Marquee from 'react-fast-marquee';
import cohotImg1 from "../../../images/basl/Marquee/cohotImg1.png";
import cohotImg2 from "../../../images/basl/Marquee/cohotImg2.png";
import cohotImg3 from "../../../images/basl/Marquee/cohotImg3.png";
import cohotImg4 from "../../../images/basl/Marquee/cohotImg4.png";
import cohotImg5 from "../../../images/basl/Marquee/cohotImg5.png";
import cohotImg6 from "../../../images/basl/Marquee/cohotImg6.png";
import cohotImg7 from "../../../images/basl/Marquee/cohotImg7.png";
import cohotImg8 from "../../../images/basl/Marquee/cohotImg8.png";
import cohotImg9 from "../../../images/basl/Marquee/cohotImg9.png";
import cohotImg10 from "../../../images/basl/Marquee/cohotImg10.png";

const index = () => {
  return (
    <div className='cohotM'>
      <div className="cohotM-head">Lifelong relationships with your cohort. <br/>
        Open access to events across the country</div>

      <Marquee  gradient={false}
        speed={67}
        pauseOnHover={false}
        className="miner-community"
        loop={0} >
        <img src={cohotImg1} loading='lazy' className='marqueImg'/>
        <img src={cohotImg2} loading='lazy' className='marqueImg'/>
        <img src={cohotImg3} loading='lazy' className='marqueImg'/>
        <img src={cohotImg4} loading='lazy' className='marqueImg'/>
        <img src={cohotImg5} loading='lazy' className='marqueImg'/>
        <img src={cohotImg6} loading='lazy' className='marqueImg'/>
        <img src={cohotImg7} loading='lazy' className='marqueImg'/>
        <img src={cohotImg8} loading='lazy' className='marqueImg'/>
        <img src={cohotImg9} loading='lazy' className='marqueImg'/>
        <img src={cohotImg10} loading='lazy' className='marqueImg'/>
      </Marquee>
    </div>
  );
};

export default index;
