import React from "react";
import "./styles.scss";

import companiesImg from "../../../images/basl/baslLogosImg.png";

const HeroSectionMb = () => {
  return (
    <div className="basl-more-info-mb">
      <div className="students-train"> 6,000+ students trained</div>
      <img src={companiesImg} loading="lazy" />
    </div>
  );
};

export default HeroSectionMb;