import React, { useEffect, useState } from "react";
import "./styles.scss";
import { data } from "./data";
import underline from "../../../images/csm/new-underline.png";

function Curriculum() {
  const [selectedCourse, setSelectedCourse] = useState(0);

  const handleScroll = (id) => {
    //scroll to the learning-content section which is clicked smoothly
    const section = document.getElementById(`tab-${id}`);

    if (section) {
      window.scrollTo({
        top: section.offsetTop - 30, // Adjust offset as needed
        behavior: "smooth",
      });
    }

    setSelectedCourse(id);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".page-navbar");
      const sections = document.querySelectorAll(".learning-content");

      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop - 30;
        const sectionBottom = sectionTop + section.offsetHeight;

        const scrollPosition = window.scrollY + navbar.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setSelectedCourse(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="learning">
      <div className="index-bg">01</div>
      <div className="learningGrey mobile-hide">Learning through cases to</div>
      <div className="learningYello mobile-hide">
        Build 6 Key Krafts
        <br />
        <img
          src={underline}
          alt="title underlines"
          placeholder="none"
          className="learningUndeline"
        />
      </div>
      <div className="learningGrey pc-hide">
        Learning through
        <br /> cases to Build
      </div>
      <div className="learningYello pc-hide">
        6 Key Krafts
        <br />
        <img
          src={underline}
          alt="title underlines"
          placeholder="none"
          className="learningUndeline"
        />
      </div>
      <div className="learning-body mobile-flex-hide">
        <div id="learning-sticky-card" className="learning-menu-container sticky-card">
          <div className="learning-menu">
            {data &&
              data.map((course, index) => (
                <div
                  key={course.id}
                  onClick={() => handleScroll(course.id)}
                  className={`l-menu-item ${
                    selectedCourse === index && "l-selected"
                  }`}
                >
                  {course.title}
                </div>
              ))}
          </div>
        </div>
        <div className="learning-scroll-tab">
          <div className="learning-container-pc">
            {data &&
              data.map((course, index) => (
                <div key={course.id} className="learning-content" id={`tab-${course.id}`}>
                  <div className="l-c-text">
                    <div className="l-c-head">{course.title}</div>
                    <div className="l-c-desc">{course.desc}</div>
                  </div>
                  <div className="l-c-img">
                    <img className="l-companyimg" src={course.image} alt={course.title} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="learnig-mob-container pc-none">
        <div className="learning-body">
          {data &&
            data.map((course, index) => (
              <div key={course.id} className="learning-content">
                <div className="l-c-text">
                  <div className="l-c-head">{course.title}</div>
                  <div className="l-c-desc">{course.desc}</div>
                </div>
                <div className="l-c-img">
                  <img className="l-companyimg" src={course.image} alt={course.title} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Curriculum;
