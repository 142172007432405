import imgMob1 from "../../../images/basl/scholarMob1.png";
import imgMob2 from "../../../images/basl/scholarMob2.png";
import imgMob3 from "../../../images/basl/scholarMob3.png";
import imgMob4 from "../../../images/basl/scholarMob4.png";
import imgMob5 from "../../../images/basl/scholarMob5.png";
import imgMob6 from "../../../images/basl/scholarMob6.png";

export const scholarships = [
  {
    id: 1,
    img: imgMob1,
    head: "Women in Sales Scholarship",
    detail:
      "For women who are not afraid to challenge gender disparity in Sales",
  },
  {
    id: 2,
    img: imgMob2,
    head: "Entrepreneurial Ambition Scholarship",
    detail: "For candidates who want to build skills for their business ambition",
  },
  {
    id: 3,
    img: imgMob3,
    head: "Sales Excellence Scholarship",
    detail: "For candidates with relevant experience in Sales",
  },
  {
    id: 4,
    img: imgMob5,
    head: "Need Based Scholarship",
    detail:
      "For candidates with a household income < 8L per annums",
  },

  {
    id: 5,
    img: imgMob4,
    head: "Merit Based Scholarship",
    detail:
      "For candidates with exceptional academic scores/ professional achievements ",
  },
  {
    id: 6,
    img: imgMob6,
    head: "Standout Performance Scholarship",
    detail: " For candidates who’ve done exceptionally well in the admission process",
  },
];
