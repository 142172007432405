import React from "react";
import "./styles.scss";

import AboutImage from "../../../images/basl/ceoSales.png";
import AboutImageMb from "../../../images/basl/salesMob.png";

const AboutProgram = () => {
  return (
    <>
      <div id="about-bsl-program">
        <div className="text-content">
          
          <h2 className="about-program-header desk">
           In the quest to become a CEO, people with a sales background<br />
          have a solid advantage as you are doing part of the same work from the get go!
          </h2>
          <h2 className="about-program-header mob">
           In the quest to become a CEO, people with a sales background <br/>
          have a solid advantage as you are doing the same work from the get go!
          </h2>
          
        </div>
        <img
          className="about-program-img"
          src={AboutImage}
          width={"100%"}
          loading="lazy"
        />
        <img
          className="about-program-img-mb"
          src={AboutImageMb}
          width={"100%"}
          loading="lazy"
        />
      </div>
    </>
  );
};

export default AboutProgram;
