import React, { useState, useEffect } from "react";
import "./styles.scss";
import underline from "../../../images/csm/new-underline.png";
import img1 from "../../../images/basl/tools/img1.png";
import img2 from "../../../images/basl/tools/img2.png";
import img3 from "../../../images/basl/tools/img3.png";
import insta from "../../../images/basl/tools/instaLogo.png";

const Tools = () => {
  const [muted, setMuted] = useState(true);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll('.hover-video')[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleMouseLeave = (index, video) => {
    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  
  const handleClick = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll('.hover-video')[index];

    if (video) {
      setMuted((prevMuted) => !prevMuted); // Toggle mute state
      video.play();
    }
  };

  // Highlighted: Added touch event handlers
  const handleTouchStart = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll('.hover-video')[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleTouchEnd = (index) => {
    const video = document.querySelectorAll('.hover-video')[index];

    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  useEffect(() => {
    const videos = document.querySelectorAll('.hover-video');

    videos.forEach(video => {
      video.muted = muted;
    });
  }, [muted]);

  const thumbnails = [img1, img2, img3];
  const videoSources = [
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+1+-+fmcg.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+2+-+Sales+Sprint+Challenge.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+3+-+Zerodor.mp4",
  ];

  return (
    <div className="marketing-tools">
      <h2 className="basl-tools-title mobile-none">IN THE TRENCHES...</h2>
      <h2 className="basl-tools-title pc-none">SALES ON FIELD</h2>
      <img src={underline} className="basl-tools-undeline" />
      <div className="trench-container">

        <div className="trenches">
          {thumbnails.map((thumbnail, index) => (
            <div className="trench" key={index}>
              <div className="reel-container">
                
                <video
                  className={`hover-video ${playingVideoIndex === index ? 'show' : 'hide'}`}
                  width="350"
                  height="500"
                  onMouseLeave={(e) => handleMouseLeave(index, e.target)}
                  // onClick={handleClick}
                  onClick={() => handleClick(index)}
                  onTouchStart={() => handleTouchStart(index)}
                  onTouchEnd={() => handleTouchEnd(index)}
                  muted={muted}
                  playsInline
                >
                  <source src={videoSources[index]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              
                <img
                  src={thumbnail}
                  alt={`thumbnail-${index}`}
                  className={`thumbnail ${playingVideoIndex === index ? 'hide' : 'show'}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                />
              
              </div>
            </div>
          ))}
        </div>
      </div>
      <a href="https://www.instagram.com/kraftshala/" target="_blank" className="see-on-insta">See more on Instagram <img className="insta" src={insta} /></a>
    </div>
  );
};

export default Tools;
