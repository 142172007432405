import React, { useState } from "react";
import "./styles.scss";
import { cardData } from "./cardData";

const index = () => {
  const [active,setActive] = useState(1);
  const [Selected,setSelected] = useState(0);

  function changeTab (index){
    setActive(index);
    setSelected(0);
  }

  function openCard (index){
    if(index === Selected){
      setSelected('');
    } else setSelected(index);
  }

  return (
    <div className="basl-journey">
      <div className="journey-head mobile-hide">
        Kraftshala’s journey is an intense experience -{" "}
      </div>
      <div className="journey-desc mobile-hide">
        Years of insights are packed into an 8 month program to deliver better
        results, faster.
      </div>

      <div className="journey-desc pc-hide">
        <span className="journey-head">
          Kraftshala’s journey is an intense experience -{" "}
        </span>
        Years of insights are packed into an 8 month program to deliver better
        results, faster.
      </div>
      <div className="tab-row mobile-flex-hide">
        <div className="left-content">
          Learning happens through <br />
          Cases, Projects, Coaching, Workshops and 1-1 interviews
        </div>
        <div className="right-content position-relative w-100 mobile-hide">
          <div className="basl-j-table">
            <div className="tab-label">Case-Based Learning </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tr">
                    <div className="colm-white">Term 1</div>
                    <div className="colm-white">Term 2</div>
                    <div className="colm-white">Term 3</div>
                    <div className="colm-white">Term 4</div>
                    <div className="colm-white">Term 5-8</div>
                  </div>
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="col-head">
                          Strategic Business Pillars + Sales & Persuasion
                          Strategies
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-sm">
                          Commercial <br />
                          functions
                        </div>
                        <div className="colm-ylo-sm">
                          Core Persuasion & <br />
                          Story-telling
                        </div>
                        <div className="colm-ylo-sm">
                          ROI & Cash flow
                          <br /> management
                        </div>
                        <div className="colm-ylo-sm">
                          Stakeholder Management
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">
                          B2B Sales Excellence: SaaS
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-lg">
                          SaaS revenue models, Metrics, Pricing models
                        </div>
                        <div className="colm-ylo-lg">
                          Building ICPs and User personas
                        </div>
                        <div className="colm-ylo-lg">
                          B2B Sales process - Prospecting, Lead Gen,
                          Qualification, Negotiation and Objection handling
                        </div>
                        <div className="colm-ylo-lg">
                          Account Based Marketing
                          <br /> Strategy +<br /> Execution
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">
                          B2C Sales Excellence:FMCG
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-md"></div>
                        <div className="colm-ylo-md">
                          FMCG Ecosystem, Revenue models & Channel Growth
                          Strategies
                        </div>
                        <div className="colm-ylo-md">
                          Distributor Metrics,  ROI and Credit Management
                        </div>
                        <div className="colm-ylo-md">
                          Shopper Engagement and Marketing
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">Digital / AI literacy</div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-md">
                          Leveraging AI for Profile building
                        </div>
                        <div className="colm-ylo-md">
                          Linkedin Sales Nav for Research
                        </div>
                        <div className="colm-ylo-md">
                          Prompt Engineering for lead gen + CRM training with
                          Leadsqaured{" "}
                        </div>
                        <div className="colm-ylo-md">
                          Automate workflows and trackers using Zapier
                        </div>
                      </div>
                    </div>
                    <div className="right-contner">
                      Practice School
                      <br />@ Workplace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table blue">
            <div className="tab-label">Business-Driving projects </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tr">
                    <div className="colm-white">Term 1</div>
                    <div className="colm-white">Term 2</div>
                    <div className="colm-white">Term 3</div>
                    <div className="colm-white">Term 4</div>
                    <div className="colm-white">Term 5-8</div>
                  </div>
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="col-head">
                          Revenue Generating Projects
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-md">
                          Case Competition -  Business Growth @ Startup
                        </div>
                        <div className="colm-ylo-md">
                          Building Persuasion @ TryKiya*
                          <br />
                          <br />
                          <b>75 hours</b>
                        </div>
                        <div className="colm-ylo-md">
                          Building Sales
                          <br />
                          Pipeline @ Rentity*
                          <br />
                          <br />
                          <b>100 hours</b>
                        </div>
                        <div className="colm-ylo-md">
                          Driving Growth
                          <br />
                          on-field @ Nestlé
                          <br />
                          <br />
                          <b>100 hours</b>
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head-specal">
                          * Companies change every batch and students also have
                          the option of picking their own project and
                          <br />
                          using this as an opportunity to help a friend / family
                          member grow their business
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">Coaching on Projects</div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-lg">
                          Creating exceptional work products like consultants
                        </div>
                        <div className="colm-ylo-lg">
                          Impactful communication
                        </div>
                        <div className="colm-ylo-lg">
                          Building &<br /> Optimizing Sales Funnels
                        </div>
                        <div className="colm-ylo-lg">
                          Driving Growth <br />
                          using field
                          <br /> insights
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">Project Showcase</div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-md">
                          Business Case Showcase
                        </div>
                        <div className="colm-ylo-md"></div>
                        <div className="colm-ylo-md">B2B Showcase</div>
                        <div className="colm-ylo-md">FMCG Showcase</div>
                      </div>
                    </div>
                    <div className="right-contner">
                      Practice School
                      <br />@ Workplace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table red">
            <div className="tab-label">Workshops </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tr">
                    <div className="colm-white">Term 1</div>
                    <div className="colm-white">Term 2</div>
                    <div className="colm-white">Term 3</div>
                    <div className="colm-white">Term 4</div>
                    <div className="colm-white">Term 5-8</div>
                  </div>
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="col-head">Better Problem Solving</div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-sm">
                          Kraftshala’s DIG
                          <br />
                          DEEP framework
                        </div>
                        <div className="colm-ylo-sm">Problem finding</div>
                        <div className="colm-ylo-sm">
                          Transferable <br />
                          consulting
                          <br /> frameworks
                        </div>
                        <div className="colm-ylo-sm">
                          Excel as an
                          <br />
                          analysis tool
                        </div>
                      </div>
                      <div className="tr">
                        <div className="col-head">Human Skills</div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-lg">
                          Crafting your stories & profiles
                        </div>
                        <div className="colm-ylo-lg">Active listening</div>
                        <div className="colm-ylo-lg">
                          Building impact and convince
                        </div>
                        <div className="colm-ylo-lg">
                          Building a<br /> stronger work
                          <br /> ethic
                        </div>
                      </div>
                    </div>
                    <div className="right-contner">
                      Practice School
                      <br />@ Workplace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table green">
            <div className="tab-label">1-1 interviews </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tr">
                    <div className="colm-white">Term 1</div>
                    <div className="colm-white">Term 2</div>
                    <div className="colm-white">Term 3</div>
                    <div className="colm-white">Term 4</div>
                    <div className="colm-white">Term 5-8</div>
                  </div>
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="col-head">
                          Module interviews + Feedback
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-lg"></div>
                        <div className="colm-ylo-lg">
                          Behavioral Mock Interview +
                          <br />
                          <br />
                          Roadmap on improvements
                        </div>
                        <div className="colm-ylo-lg">
                          B2B Mock
                          <br /> Interview +
                          <br />
                          <br />
                          Roadmap on improvements
                        </div>
                        <div className="colm-ylo-lg">
                          FMCG Mock Interview +
                          <br />
                          <br />
                          Roadmap on improvements
                        </div>
                      </div>
                    </div>
                    <div className="right-contner">
                      Practice School
                      <br />@ Workplace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="journey-mobile">
        <div className="journey-container">
          <div className="journey-tab">
            <div className={`term ${active === 1 && 'active-term'}`} onClick={()=> changeTab(1)}>Term 1</div>
            <div className={`term ${active === 2 && 'active-term'}`} onClick={()=> changeTab(2)}>Term 2</div>
            <div className={`term ${active === 3 && 'active-term'}`} onClick={()=> changeTab(3)}>Term 3</div>
            <div className={`term ${active === 4 && 'active-term'}`} onClick={()=> changeTab(4)}>Term 4</div>
            <div className={`term ${active === 5 && 'active-term'}`} onClick={()=> changeTab(5)}>Term 5-8</div>
          </div>
        </div>
        <div className="journey-cards-container">
          {cardData && cardData.filter(e => e.term === active).map((card,index) => {
            return  <div className="journey-card" style={{backgroundColor: card.bg}} onClick={() => openCard(index)}>
              <div className="j-c-flex">
                <div className="j-c-flex-head">{card.head}</div>
                {index !== Selected ? <svg class="" xmlns="http://www.w3.org/2000/svg" width="21" height="10" viewBox="0 0 21 10" fill="none"><path d="M1 1L10.5 9L20 1" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  : <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none"><path d="M19 9L10 0.999999L1 9" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>}
              </div>
              {index === Selected &&
              <div className="j-c-flex-desc">
                <ul>
                  {card.desc.map((desc,index) => <li>{desc}</li>)}
                  
                </ul>
                <div className="disc"> {card.disc}</div>
              </div> }
            </div>;
          })}
         
        </div>
      </div>
    </div>
  );
};

export default index;
