import News1 from "../../../images/basl/news/news1.png";
import newsCom1 from "../../../images/basl/news/news1Comp.png";
import News2 from "../../../images/basl/news/news2.png";
import newsCom2 from "../../../images/basl/news/news2Comp.png";
import News3 from "../../../images/basl/news/news3.png";
import newsCom3 from "../../../images/basl/news/news3Comp.png";
import News4 from "../../../images/basl/news/news4.png";
import newsCom4 from "../../../images/basl/news/news4Comp.png";
import News5 from "../../../images/basl/news/news5.png";
import newsCom5 from "../../../images/basl/news/news5Comp.png";
import News6 from "../../../images/basl/news/news6.png";
import newsCom6 from "../../../images/basl/news/news6Comp.png";
import News7 from "../../../images/basl/news/news7.png";
import newsCom7 from "../../../images/basl/news/news7Comp.png";
import News8 from "../../../images/basl/news/news9.png";
import newsCom8 from "../../../images/basl/news/news8Comp.png";

export const newsData = [
  {
    image: News1,
    compImage: newsCom1,
    desc: 'Kraftshala partners with Nestlé to inspire people to pursue sales careers',
    descShort: 'Kraftshala partners with Nestlé to inspire',
    readMore: true,
    link: 'https://www.adgully.com/kraftshala-partners-with-nestle-to-inspire-people-to-pursue-sales-careers-145028.html'
  },
  {
    image: News2,
    compImage: newsCom2,
    desc: 'Kraftshala announces free training sessions for professionals',
    descShort: 'Kraftshala announces free training sessions ',
    readMore: true,
    link: 'https://www.afaqs.com/companies/kraftshala-announces-free-training-sessions-for-professionals-and-b-school-students'
  },
  {
    image: News3,
    compImage: newsCom3,
    desc: 'Hindi Learners Can Benefit From These New Age Courses In Native Language',
    descShort: 'Hindi Learners Can Benefit From These New',
    link: 'https://www.ndtv.com/education/hindi-learners-can-benefit-from-these-new-age-courses-in-native-language-5675596/amp/1'
  },
  {
    image: News4,
    compImage: newsCom4,
    desc: 'Kraftshala launches PG Program in Sales and Business leadership',
    descShort: 'Kraftshala launches PG Program in Sales and',
    readMore: true,
    link: 'https://education21.in/kraftshala-launches-pg-program-in-sales-and-business-leadership/'
  },
  {
    image: News5,
    compImage: newsCom5,
    desc: 'Unlocking Career Potential: Kraftshala Teams Up with Adarsh Menon',
    descShort: 'Unlocking Career Potential: Kraftshala Teams',
    readMore: true,
    link: 'https://www.edukida.in/trending/unlocking-career-potential-kraftshala-teams-up-with-zoomcars-adarsh-menon-for-sales-and-business-career-session/'
  },
  {
    image: News6,
    compImage: newsCom6,
    desc: 'Varun Satia | Founder & CEO Kraftshala | India A-List | NewsX',
    descShort: 'Varun Satia | Founder & CEO Kraftshala',
    link: 'https://www.youtube.com/watch?si=ic0LGN6ht07VNc-A&v=rYchpbMMJr0&feature=youtu.be'
  },
  {
    image: News7,
    compImage: newsCom7,
    desc: 'How Kraftshala prepares FMCG, SaaS, and marketing professionals',
    descShort: 'How Kraftshala prepares FMCG, SaaS',
    readMore: true,
    link: 'https://cxotoday.com/interviews/tailored-training-excellence-how-kraftshala-prepares-fmcg-professionals-for-industry-challenges/'
  },
  {
    image: News8,
    compImage: newsCom8,
    desc: 'Kraftshala to empower mid-level agency managers for leadership roles',
    descShort: 'Kraftshala to empower mid-level agency ',
    readMore: true,
    link: 'https://www.medianews4u.com/kraftshala-to-empower-mid-level-agency-managers-for-leadership-roles-with-the-leadership-catalyst-series/'
  },
  
];
