import React from "react";
import Slider from "react-slick";
// import Card7 from "../../TestimonialCard/Card7/index";
import Card from "./card";
import { placementsData } from "./data2";
// import Alumni from "../Alumni/Alumni";
import underline from "../../../images/csm/new-underline.png";
import "./styles.scss";
import { useState } from "react";

function AlumniCarousal() {
  const [cardData, setCardData] = useState(placementsData[0]);

  const settings = {
    prevArrow: "",
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {/* <Alumni data={cardData} /> */}
      <div className="mlp-main-placements mlp-main basl-main-placements">
        <div className="placements-container basl-placement-container">
          <div className="bsl-alumni-header">
            <div className="sub-header">ALUMNI STORIES</div>
            <h2 className="main-header">
            Kraftshala has trained 6000+ folks from India’s top B-Schools and organizations in the last 7 years{" "}
            </h2>
            <div className="new-underline">
              <img src={underline} loading="lazy" className="alumni-underline" />
            </div>
            <p className="desc">
              Listen to some of their amazing stories!
            </p>
          </div>
          <div className="placement-slider basl-alumni-slider">
            <Slider {...settings}>
              {placementsData.map((info, key) => (
                <Card
                  key={key}
                  image={info.image}
                  video={info.video}
                  name={info.name}
                  batch={info.batch}
                  alt={info.alt}
                  company={info.company}
                  description={info.testimonial}
                  profile={info.profile}
                  jobRole={info.jobRole}
                  specialisation={info.specialisation}
                  extraText={info.extra_text ? info.extra_text : ""}
                  uni={info.uni}
                  fn={setCardData}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlumniCarousal;
