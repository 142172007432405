import Hari from "../../../images/basl/leaders/hari.png";
import Harsh from "../../../images/basl/leaders/harsh.png";
import Eshu from "../../../images/basl/leaders/eshu.png";
import Sethi from "../../../images/basl/leaders/sethi.png";
import Ayushi from "../../../images/basl/leaders/ayushi.png";
import Shasank from "../../../images/basl/leaders/shasank.png";
import Fahad from "../../../images/basl/leaders/fahadbk.png";
import Himanchu from "../../../images/basl/leaders/him.png";
import Achit from "../../../images/basl/leaders/achit.png";
import Shaz from "../../../images/basl/leaders/shaz.png";
import Pritika from "../../../images/basl/leaders/pritika.png";
import Aman from "../../../images/basl/leaders/aman.png";
import Tanvi from "../../../images/basl/leaders/tanvi.png";
import Tarun from "../../../images/basl/leaders/tarun.png";
import Abhijit from "../../../images/basl/leaders/abhij.png";
import Sourav from "../../../images/basl/leaders/sourav.png";
import Eshan from "../../../images/basl/leaders/eshan.png";
import Sanjay from "../../../images/basl/leaders/sanjay.png";
import kaush from "../../../images/basl/leaders/kaush.png";
import Vagisha from "../../../images/basl/leaders/vagisha.png";
import Varun from "../../../images/basl/leaders/varunbk.png";
import Anushka from "../../../images/basl/leaders/anushka.png";
import Roshini from "../../../images/basl/leaders/roshini.png";
import Darshan from "../../../images/basl/leaders/darshan.png";

export const MentorData = [
  {
    head: "Strategic Business Pillars",
    card: [
      {
        image: Hari,
        name: "Hari Hara",
        position: "ex-HR Business Partner, ",
        company: "Amazon",
        place: "XLRI 2011",
      },
      {
        image: Harsh,
        name: "Harsh Kumar",
        position: "Senior DGM,",
        company: "NCRTC",
        place: "FMS 2010",
      },
      {
        image: Eshu,
        name: "Eshu Sharma",
        position: "Co-Founder,",
        company: "Kraftshala, ex-HUL",
        place: "FMS 2015",
      },
      {
        image: Sethi,
        name: "Varun Sethi",
        position: "Management Consultant,",
        company: "Accenture; Ex- BCG",
        place: "IIM K 2020",
      },
      {
        image: Ayushi,
        name: "Ayushi Mona",
        position: "Director Marketing @",
        company: "Delhivery",
        place: "MICA 2018",
      },
      {
        image: Shasank,
        name: "Sashank Vissa",
        position: "Senior Consultant @",
        company: "IBM",
        place: "IIM Indore 2021",
      },
    ],
  },{
    head: "B2B SaaS Sales",
    card: [{
      image: Fahad,
      name: "Fahad Mistry",
      position: "VP, Sales and Strategy,",
      company: "Turbohire",
      place: "Ex-BlueOceans",
    },{
      image: Himanchu,
      name: "Himanshu Sharma",
      position: "Sales Director",
      company: "Darwinbox",
      place: "IIM A 2017",
    },{
      image: Achit,
      name: "Archit Kashyap",
      position: "Sales, Clevertap",
      company: "ex-WebEngage",
      place: "MDI 2016",
    },{
      image: Shaz,
      name: "Shazan Baqri",
      position: "Demand Gen and",
      company: "Partnerships @ Turbohire",
      place: "Ex-Abentra",
    },{
      image: Pritika,
      name: "Pritika Khorana",
      position: "AVP Sales, Konnect",
      company: "Ex-Happay",
      place: "",
    },{
      image: Aman,
      name: "Aman Saxena",
      position: "Chief Manager,",
      company: "Business Standard",
      place: "NMIMS 2016",
    },{
      image: Tanvi,
      name: "Tanvi Butalia",
      position: "Senior Account Manager",
      company: "iZooto, Ex-Harappa,",
      place: "LeverageEdu",
    },{
      image: Tarun,
      name: "Tarun Kumar",
      position: "Director - Sales @",
      company: "TurboHire",
      place: "ex-InFreedo, Linkedin",
    }]
  },{
    head: "FMCG Sales",
    card: [
      {
        image: Abhijit,
        name: "Abhijit Arora",
        position: "Ex-Minimalist | Ex-",
        company: "L'Oreal",
        place: "IIM L 2009"
      },{
        image: Sourav,
        name: "Sourav Choudhury",
        position: "Prof, SPJAIN and",
        company: "IIM Mumbai",
        place: "Ex-Director, McCormick"
      },{
        image: Eshan,
        name: "Eshan Sett",
        position: "Ex-National Manager,",
        company: "Marico",
        place: "FMS 2014"
      },{
        image: Sanjay,
        name: "Sanjog Singh Ahuja",
        position: "Customer Marketing",
        company: "Manager, Mars Wrigley",
        place: "Ex- Nestlé"
      },{
        image: kaush,
        name: "Kaustubh Tripathi",
        position: "Growth, Reliance Retail",
        company: "Ex- ITC Sales",
        place: ""
      },{
        image: Vagisha,
        name: "Vagisha Sharma",
        position: "Brand Manager, Sunrise,",
        company: "Ex-Sales",
        place: "IIM L 2019"
      }
    ]
  },{
    head: 'BPS and Human Skills',
    card: [{
      image: Varun,
      name: "Varun Satia",
      position: "Founder, Kraftshala",
      company: "ex-MAGGI India",
      place: "FMS 2011"
    },{
      image: Anushka,
      name: "Anushka Kapur",
      position: "Program Lead, Kraftshala",
      company: "Ashoka 2018",
      place: ""
    },{
      image: Roshini,
      name: "Roshini Maria",
      position: "Human Skills, Kraftshala",
      company: "ex-Teach For India 2019",
      place: ""
    },{
      image: Darshan,
      name: "Darshan",
      position: "Human Skills, Kraftshala",
      company: "ex-The/Nudge Institute",
    }]
  }
];
