import React from "react";
import underline from "../../../images/csm/new-underline.png";
import "./styles.scss";
import CardMob from "./CardMob";

const OnGroundProject = () => {
  return (
    <section className="basl-on-ground-project">
      <div className="index-bg">02</div>
      <h2 className="ground-project-header">
        Business Generating /<br className="pc-hide" /> Field Projects 
        <br className="mobile-hide" />
        with Industry’s Top Brands
      </h2>
      <img src={underline} className="ground-project-underline" />

      <CardMob />
    </section>
  );
};

export default OnGroundProject;
